<template>
  <new-page>
    <div v-wechat-title="$route.meta.title"></div>
    <!-- 文章分类 -->
    <div class="article_category">
      <div class="article_category_title">分类：</div>
      <div class="article_category_info">
        <el-button
          text
          v-for="categorys in allCategorys"
          :key="categorys.id"
          :id="'category_' + categorys.id"
          class="category"
          @click="onChangeCategory(categorys.id)"
          >{{ categorys.category_name }}</el-button
        >
      </div>
    </div>
    <!-- 文章标签 -->
    <div class="article_tag">
      <div class="article_tag_title">标签：</div>
      <div class="article_tag_info">
        <el-button
          text
          v-for="tags in allTags"
          :key="tags.id"
          :id="'tag_' + tags.id"
          class="tag"
          @click="onChangeTag(tags.id)"
          >{{ tags.tag_name }}</el-button
        >
      </div>
    </div>
    <!-- 搜索框（待定） -->
    <el-input
      v-model="selectArticleFrom.title"
      placeholder="请输入关键词..."
      size="large"
      class="search_input"
      @change="$refs.ArticleListInfo.selectArticles()"
      clearable
    >
      <template #append>
        <span
          style="cursor: pointer"
          @click="$refs.ArticleListInfo.selectArticles()"
          >搜 索</span
        >
      </template>
    </el-input>
    <!-- 文章列表 -->
    <NewArticleListInfo
      ref="ArticleListInfo"
      :from="selectArticleFrom"
    ></NewArticleListInfo>
  </new-page>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import { SELECTALLTAGS, SELECTALLCATEGORYS } from '../api/api'
import { onMounted, onUpdated } from 'vue'
import NewArticleListInfo from '../components/NewArticleListInfo.vue'
import { useRoute } from 'vue-router'
import NewPage from '../../src/components/NewPage'
export default {
  components: { NewArticleListInfo, NewPage },
  setup() {
    const ArticleListInfo = ref(null)
    const route = useRoute()
    onMounted(() => {
      getAllTags()
      getAllCategorys()
      selectArticleFrom.tag_id = route.params.tag_id
      selectArticleFrom.category_id = route.params.category_id
      selectArticleFrom.title = route.params.title
      ArticleListInfo.value.selectArticles()
    })
    onUpdated(() => {
      addActivateStyle(route.params.tag_id, route.params.category_id)
    })
    // 标签信息
    const allTags = ref([])
    // 查询所有标签信息
    const getAllTags = () => {
      SELECTALLTAGS().then((response) => {
        if (response.code === 0) {
          allTags.value = response.data
        }
      })
    }
    // 分类信息
    const allCategorys = ref([])
    // 查询所有分类信息
    const getAllCategorys = () => {
      SELECTALLCATEGORYS().then((response) => {
        if (response.code === 0) {
          allCategorys.value = response.data
        }
      })
    }
    // 搜索文章表单
    const selectArticleFrom = reactive({
      category_id: null,
      tag_id: null,
      title: ''
    })
    // 选中分类修改样式
    const onChangeCategory = (id) => {
      if (selectArticleFrom.category_id === id) {
        // 重复点击，取消选中
        document
          .getElementById('category_' + selectArticleFrom.category_id)
          .classList.remove('activateStyle')
        selectArticleFrom.category_id = null
      } else {
        // 切换选中
        if (selectArticleFrom.category_id != null) {
          document
            .getElementById('category_' + selectArticleFrom.category_id)
            .classList.remove('activateStyle')
        }
        selectArticleFrom.category_id = id
        if (document.getElementById('category_' + id) != null) {
          document
            .getElementById('category_' + id)
            .classList.add('activateStyle')
        }
      }
      ArticleListInfo.value.selectArticles()
    }
    // 选中标签修改样式
    const onChangeTag = (id) => {
      // 重复点击取消选中
      if (selectArticleFrom.tag_id === id) {
        document
          .getElementById('tag_' + selectArticleFrom.tag_id)
          .classList.remove('activateStyle')
        selectArticleFrom.tag_id = null
      } else {
        // 重新选中
        if (selectArticleFrom.tag_id != null) {
          document
            .getElementById('tag_' + selectArticleFrom.tag_id)
            .classList.remove('activateStyle')
        }
        selectArticleFrom.tag_id = id
        if (document.getElementById('tag_' + id) != null) {
          document.getElementById('tag_' + id).classList.add('activateStyle')
        }
      }

      ArticleListInfo.value.selectArticles()
    }
    // 首页跳转携带标签/分类参数，触发修改选中样式
    const addActivateStyle = (tag_id, category_id) => {
      if (tag_id !== undefined) {
        if (document.getElementById('tag_' + tag_id) != null) {
          document
            .getElementById('tag_' + tag_id)
            .classList.add('activateStyle')
          selectArticleFrom.tag_id = tag_id
        }
      }
      if (category_id !== undefined) {
        if (document.getElementById('category_' + category_id) != null) {
          document
            .getElementById('category_' + category_id)
            .classList.add('activateStyle')
          selectArticleFrom.category_id = category_id
        }
      }
    }
    return {
      ArticleListInfo,
      allTags,
      allCategorys,
      onChangeCategory,
      onChangeTag,
      selectArticleFrom
    }
  }
}
</script>

<style scoped>
.article_category {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 200px; */
  background-color: #d1dbe5;
  margin-top: 80px;
}

.article_category_title {
  /* font-weight: bold; */
  width: 120px;
  padding: 16px;
}

.category,
.tag {
  /* margin-right: 32px; */
  margin: 16px 16px;
  font-size: 14px;
  color: #000000;
  padding: 4px;
}

.article_category_info {
  width: 100%;
  background-color: #fff;
}

.article_tag {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  background-color: #d1dbe5;
}

.article_tag_title {
  width: 120px;
  padding: 16px;
}

.article_tag_info {
  width: 100%;
  background-color: #fff;
}

.search_input {
  margin-top: 8px;
}

.activateStyle {
  color: red;
  border: thin solid red;
  border-radius: 0;
  /* padding: 4px; */
}

.activateStyle:hover {
  color: #f56c6c;
  border: thin dashed #f56c6c;
}

@media screen and (max-width: 560px) {
  .article_category_info,
  .article_tag_info {
    white-space: nowrap;
    overflow-x: scroll;
  }

  .article_tag_title,
  .article_category_title {
    padding: 8px;
    width: 70px;
    font-size: 14px;
  }

  .category,
  .tag {
    margin: 8px 16px;
  }
}
</style>
<!-- <style src="../css/articleList.css" scoped>
</style> -->
